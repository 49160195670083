<template>
  <div>
    <v-container >
      <v-row class="elevation-1">
        <v-col>
          <v-select id="tmc-misc-report-type"
            v-model="reportConfig"
            :items="reports"
            item-text="description"
            item-value="config"
            label="Type of report"
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFiscalYears')">
        <v-col>
          <v-select id="tmc-misc-report-fy"
            v-model="criteria.fy"
            :items="fiscalYearList"
            item-text="name"
            item-value="year"
            label="By Fiscal Year"
            @change="searchByCriteria"
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="elevation-1"
        v-if="showReportConfig('showWkst')">
        <v-col>
          <MultiItemSelect id="tmc-misc-report-by-wkst"
            label="By Workstation"
            item-text="wkstInt"
            item-value="_id"
            :item-list="wkstIdLists"
            :items="criteria.wkst"
            @selected-items="selectedWkstList"
          />
          </v-col>
      </v-row>

      <v-row class="elevation-1"
        v-if="showReportConfig('showFromToDates')">
        <v-col id="tmc-misc-report-from-date">
          <ClarionDateControl
            v-model="criteria.fromDate"
            :isRequired="true" label="From Date" />
        </v-col>
          <v-col>
            <ClarionDateControl
              v-model="criteria.toDate"
              :isRequired="true" label="To Date" />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showToDates')">
        <v-col>
          <ClarionDateControl
            v-model="criteria.toDate"
            :isRequired="true" label="To Date" />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showFunds')">
        <v-col>
          <MultiItemSelect
            label="By Fund"
            item-text="fundDesc"
            item-value="_id"
            :item-list="fundList"
            :items="criteria.funds"
            @selected-items="selectedFunds"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showSources')">
        <v-col>
          <MultiItemSelect
            label="By Source"
            item-text="sourceDesc"
            item-value="_id"
            :item-list="sourceList"
            :items="criteria.sourceSubSources"
            @selected-items="selectedSourceSubSources"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showUnits')">
        <v-col>
          <MultiItemSelect
            label="By Unit Code Numbers"
            item-text="unitDesc"
            item-value="_id"
            :item-list="miscUnitCodeList"
            :items="criteria.unitCodeNumbers"
            @selected-items="selectedUnits"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showTaxDistricts')">
        <v-col>
          <MultiItemSelect
            label="By Tax Districts"
            item-text="taxDistrictCodeAndName"
            item-value="_id"
            :item-list="taxDistrictList"
            :items="criteria.taxDistricts"
            @selected-items="selectedTaxDistricts"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showBanks')">
        <v-col>
          <MultiItemSelect
            label="By Banks"
            item-text="bankAltKey"
            item-value="_id"
            :item-list="bankList"
            :items="criteria.banks"
            @selected-items="selectedBanks"
          />
        </v-col>
      </v-row>
      <v-row class="elevation-1"
        v-if="showReportConfig('showVoid')">
        <v-col>
          <v-select id="misc-criteria-isVoid"
            v-model="criteria.isVoid"
            :items="isVoidOptions"
            item-text="caption"
            item-value="value"
            label="By Type"
            >
          </v-select>
        </v-col>
      </v-row>
      <v-row class="elevation-1">
        <v-col
          id="tmc-misc-report-print-details"
          v-if="showReportConfig('showPrintDetails')">
          <v-checkbox
            v-model="criteria.printDetails"
            label="Print Details">
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <ReportChip
          :to="reportConfig.route"
          :queryString="queryString"
          :isValid="validateCriteria"
          caption="View Report"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MultiItemSelect from '@/components/common/base/MultiItemSelect.vue';
import ReportChip from '../../common/base/report/ReportChip.vue';
import ClarionDateControl from '../../common/ClarionDateControl.vue';
import { calculateFiscalYears, toShortDate, toFiscalYear } from '../../../util/shared/tmc-global';
import { reportList, queryStringCalc } from './report-util';
import { getRawJwt } from '../../../util/jwt';

const fundBaseCrudKey = 'sharedFund';
const sourceBaseCrudKey = 'cmnSource';
const unitBaseCrudKey = 'miscUnit';
const taxDistrictBaseCrudKey = 'sharedTaxDistrict';
const bankCrudKey = 'sharedBank';

export default {
  name: 'MiscReportComp',
  components: {
    ReportChip,
    ClarionDateControl,
    MultiItemSelect,
  },
  data: () => ({
    miscBaseCrudKey: 'miscTransaction',
    criteria: {
      fy: {},
      funds: [],
      sourceSubSources: [],
      unitCodeNumbers: [],
      taxDistricts: [],
      banks: [],
      wkst: [],
      fromDate: undefined,
      toDate: undefined,
      printDetails: false,
    },
    wkstLists: [],
    reportConfig: {
      route: '',
      filters: {},
    },
    defaultCriteria: {
      fy: undefined,
    },
  }),
  created() {
    this.loadConfig({ requiredScope: 'miscConfig' });
    this.initializeFiscalYears();
    this.loadTaxDistricts();
    this.loadbanks();
    this.initCriteria();
  },
  mounted() {
    this.defaultCriteria.fy = toFiscalYear(new Date(this.todaysDate));
  },
  computed: {
    ...mapGetters('base/crud', [
      'items',
    ]),
    ...mapState({
      regYears: (state) => state.misc.miscTransaction.regYears,
      wkstNumList: (state) => state.misc.miscTransaction.wkstNumList,
    }),
    ...mapGetters([
      'todaysDate',
    ]),
    reports() {
      return [
        ...reportList(),
      ];
    },
    ...mapState({
      warSettingItem: (state) => state.SystemConfig.warSettingItem,
    }),
    fundList() {
      const items = this.items(fundBaseCrudKey);
      const updatedItem = items.map((i) => ({
        ...i,
        fundDesc: `${i.fund} ${i.description || ''}`,
      }));
      return [...updatedItem];
    },
    sourceList() {
      const items = this.items(sourceBaseCrudKey);
      const updatedItem = items.map((i) => {
        const firstPartDesc = `${i.sourceSubSourceNumber} ${i.sourceDescription || ''}`;
        const secondPartDesc = i.subSourceDescription;
        return {
          ...i,
          sourceDesc: secondPartDesc == null || '' ? `${i.sourceSubSourceNumber} ${i.sourceDescription}` : `${firstPartDesc} - ${secondPartDesc}`,
        };
      });
      return [...updatedItem];
    },
    miscUnitCodeList() {
      const items = this.items(unitBaseCrudKey);
      const updatedItem = items.map((i) => ({
        ...i,
        unitDesc: `${i.unitCodeNumber} ${i.description || ''}`,
      }));
      return [...updatedItem];
    },
    taxDistrictList() {
      const items = this.items(taxDistrictBaseCrudKey);
      const updatedItem = items.map((i) => ({
        ...i,
        taxDistrictCodeAndName: `${i.districtCode} ${i.districtName || ''}`,
      }));
      return [...updatedItem];
    },
    bankList() {
      const items = this.items(bankCrudKey) || [];
      const updatedBankObjs = items.map((b) => ({
        ...b,
        bankAltKey: ` ${b.altKeyBase || ''} ${b.bankName || ''}`,
      }));
      return [...updatedBankObjs];
    },
    wkstIdLists() {
      const wkstNoList = (this.wkstNumList || []);
      return wkstNoList;
    },
    fiscalYearList() {
      const registerdYears = (this.regYears || {});
      const allowAll = false;
      return calculateFiscalYears(registerdYears, allowAll);
    },
    currentFiscalYear() {
      return toFiscalYear(new Date(this.todaysDate));
    },
    queryString() {
      return queryStringCalc({
        reportConfig: this.reportConfig,
        criteria: this.criteria,
        token: this.getAuthToken,
        defaultCriteria: this.defaultCriteria,
      });
    },
    validateCriteria() {
      let isValid = true;
      if (this.criteria.fy == null) {
        isValid = false;
      }
      return isValid;
    },
    getAuthToken() {
      return getRawJwt();
    },
    isVoidOptions() {
      return [
        {
          value: null,
          caption: 'All',
        },
        {
          value: true,
          caption: 'Void',
        },
      ];
    },
  },
  methods: {
    ...mapActions('misc/miscTransaction', [
      'loadFiscalRegYears',
      'loadWkstList',
    ]),
    ...mapActions('shared/fund', [
      'loadFunds',
    ]),
    ...mapActions('shared/source', [
      'loadCmnSource',
    ]),
    ...mapActions('misc/schoolUnit', [
      'loadMiscUnitCode',
    ]),
    ...mapActions('shared/taxDistrict', [
      'loadTaxDistrict',
    ]),
    ...mapActions('shared/bank', [
      'loadBanks',
    ]),
    ...mapActions('SystemConfig', [
      'loadConfig',
    ]),
    combinationFields(item) {
      const fields = `${item.sourceNumber}.${item.subSourceNumber}`;
      return fields;
    },
    mapCollectionVal(itemList, itemValue) {
      return [...(itemList || []).map((item) => item[`${itemValue}`])];
    },
    loadFundData(fiscalYear) {
      const sortBy = [
        {
          column: 'fund',
          direction: 'asc',
        },
      ];
      const criteria = { fiscalYear, sortBy };
      this.loadFunds({ criteria, baseCrudKey: fundBaseCrudKey }).then(() => {
        this.criteria.funds = this.mapCollectionVal(this.fundList, '_id');
      });
    },
    loadSourceData(fiscalYear) {
      this.loadCmnSource({
        criteria: {
          fiscalYear,
          isSkipMiscellaneousTransaction: false,
          sortBy: [
            {
              column: 'sourceSubSourceNumber',
              direction: 'asc',
            },
          ],
        },
        baseCrudKey: sourceBaseCrudKey,
      }).then(() => {
        this.criteria.sourceSubSources = this.mapCollectionVal(this.sourceList, '_id');
      });
    },
    loadMiscUnitCodeData(fiscalYear) {
      const criteria = { fiscalYear };
      this.loadMiscUnitCode({ criteria, baseCrudKey: unitBaseCrudKey }).then(() => {
        this.criteria.unitCodeNumbers = this.mapCollectionVal(this.miscUnitCodeList, '_id');
      });
    },
    loadTaxDistricts() {
      const criteria = {};
      this.loadTaxDistrict({ criteria, baseCrudKey: taxDistrictBaseCrudKey }).then(() => {
        this.criteria.taxDistricts = this.mapCollectionVal(this.taxDistrictList, '_id');
      });
    },
    loadbanks() {
      this.loadBanks({
        criteria: {
          notEmptyAltKey: true,
          limit: 0,
        },
        baseCrudKey: bankCrudKey,
      }).then(() => {
        this.criteria.banks = this.mapCollectionVal(this.bankList, '_id');
      });
    },
    loadWkstNumbers(fiscalYear) {
      this.loadWkstList({ fiscalYear, baseCrudKey: 'miscTransaction' }).then(() => {
        this.criteria.wkst = this.mapCollectionVal(this.wkstIdLists, '_id');
      });
    },
    searchByCriteria() {
      this.loadFundData(this.criteria.fy);
      this.loadSourceData(this.criteria.fy);
      this.loadMiscUnitCodeData(this.criteria.fy);
      this.loadWkstNumbers(this.criteria.fy);
    },
    initializeFiscalYears() {
      this.loadFiscalRegYears({ baseCrudKey: this.miscBaseCrudKey }).then(() => {
        const regYears = this.fiscalYearList || [];
        if ((regYears || []).length > 0) {
          this.criteria.fy = (regYears[regYears.length - 1] || {}).year;
          this.loadFundData(this.criteria.fy);
          this.loadSourceData(this.criteria.fy);
          this.loadMiscUnitCodeData(this.criteria.fy);
          this.loadWkstNumbers(this.criteria.fy);
        }
      }).catch((e) => {
        console.warn(e);
      });
    },
    selectedFunds(val) {
      this.criteria.funds = val;
    },
    selectedSourceSubSources(val) {
      this.criteria.sourceSubSources = val;
    },
    selectedUnits(val) {
      this.criteria.unitCodeNumbers = val;
    },
    selectedTaxDistricts(val) {
      this.criteria.taxDistricts = val;
    },
    selectedBanks(val) {
      this.criteria.banks = val;
    },
    selectedWkstList(val) {
      this.criteria.wkst = val;
    },
    showReportConfig(configField) {
      return this.reportConfig.filters[`${configField}`];
    },
    initCriteria() {
      this.criteria.fromDate = toShortDate(this.todaysDate);
      this.criteria.toDate = toShortDate(this.todaysDate);
    },
  },
};

</script>
