<template>
  <div>
    <MiscReportComp />
  </div>
</template>

<script>
import MiscReportComp from '@/components/Misc/Reports/MiscReportComp.vue';

export default {
  name: 'MiscReport',
  components: {
    MiscReportComp,
  },
};

</script>
