import { generateQueryString } from '../../common/base/report/report-util';

export const queryStringCalc = ({
  reportConfig,
  criteria,
  token,
  defaultCriteria,
}) => {
  const params = [];

  if (reportConfig.filters.showFromToDates && criteria.fromDate) {
    params.push(`fromDate=${criteria.fromDate}`);
  }

  if ((reportConfig.filters.showFromToDates || reportConfig.filters.showToDates)
    && criteria.toDate) {
    params.push(`toDate=${criteria.toDate}`);
  }

  if (reportConfig.filters.showFiscalYears && criteria.fy) {
    const fy = criteria.fy
      ? criteria.fy : defaultCriteria.fy;
    params.push(`fiscalYear=${fy}`);
  }

  if (reportConfig.filters.showPrintDetails) {
    params.push(`printDetails=${criteria.printDetails}`);
  }

  if (reportConfig.filters.showVoid) {
    params.push(`isVoid=${criteria.isVoid}`);
  }

  if (reportConfig.filters.showFunds && (criteria.funds || []).length > 0) {
    const funds = criteria.funds.join(',');
    params.push(`funds=${funds}`);
  }

  if (reportConfig.filters.showSources && (criteria.sourceSubSources || []).length > 0) {
    const sourceSubSources = criteria.sourceSubSources.join(',');
    params.push(`sourceSubSources=${sourceSubSources}`);
  }

  if (reportConfig.filters.showUnits && (criteria.unitCodeNumbers || []).length > 0) {
    const unitCodeNumbers = criteria.unitCodeNumbers.join(',');
    params.push(`unitCodeNumbers=${unitCodeNumbers}`);
  }

  if (reportConfig.filters.showTaxDistricts && (criteria.taxDistricts || []).length > 0) {
    const taxDistricts = criteria.taxDistricts.join(',');
    params.push(`taxDistricts=${taxDistricts}`);
  }

  if (reportConfig.filters.showBanks && (criteria.banks || []).length > 0) {
    const banks = criteria.banks.join(',');
    params.push(`banks=${banks}`);
  }
  if (reportConfig.filters.showWkst && (criteria.wkst || []).length > 0) {
    const wkst = criteria.wkst.join(',');
    params.push(`wkst=${wkst}`);
  }
  // }
  if (token) {
    params.push(`token=${token}`);
  }
  return generateQueryString(params);
};

export const reportList = () => {
  const list = [
    {
      description: 'Receipts By Date',
      config: {
        route: 'misc/receipts-by-date',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showFunds: true,
          showSources: true,
          showUnits: true,
          showPrintDetails: true,
          showTaxDistricts: true,
          showVoid: true,
        },
      },
    },
    {
      description: 'Receipts By Bank',
      config: {
        route: 'misc/receipts-by-bank',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showBanks: true,
          showWkst: true,
          showPrintDetails: true,
        },
      },
    },
    {
      description: 'By Fund for Apportionment',
      config: {
        route: 'misc/receipts-by-fund-for-apportionment',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showFunds: true,
          showSources: true,
          showUnits: true,
          showPrintDetails: true,
          showTaxDistricts: true,
        },
      },
    },
    {
      description: 'By Source for Budget',
      config: {
        route: 'misc/receipts-by-source-for-budget',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showFunds: true,
          showSources: true,
          showPrintDetails: true,
          showTaxDistricts: true,
        },
      },
    },
    {
      description: 'By Code for Apportionment',
      config: {
        route: 'misc/receipts-by-code-for-apportionment',
        filters: {
          showFiscalYears: true,
          showFromToDates: true,
          showUnits: true,
          showPrintDetails: true,
        },
      },
    },
  ];
  return list;
};

export default {
  reportList,
  queryStringCalc,
};
